import Vue from "vue"
import App from "./App.vue"
import store from "@/store/store"
import Vuesax from "vuesax"
import "material-icons/iconfont/material-icons.css" //Material Icons
import "vuesax/dist/vuesax.css" // Vuesax
import vueDebounce from 'vue-debounce'
import axios from "axios"
import VueSweetalert2 from "vue-sweetalert2"
import 'sweetalert2/dist/sweetalert2.min.css'
import ApiService from "@/http-common.js"
import i18n from "@/i18n"
import VueTour from "vue-tour"
import router from "@/router"
import "../themeConfig.js"
import "prismjs"
import "@/filter"
// Import TinyMCE
import 'tinymce/tinymce'

// Default icons are required for TinyMCE 5.3 or above
import 'tinymce/icons/default'

// A theme is also required
import 'tinymce/themes/silver'

// Any plugins you want to use has to be imported
import 'tinymce/plugins/preview'
import 'tinymce/plugins/link'
import 'tinymce/skins/ui/oxide/skin.min.css'
// import IdleVue from 'idle-vue'
const moment = require('moment')
    // import * as Sentry from '@sentry/browser'
    // import * as Integrations from '@sentry/integrations'

// Sentry.init({
//   dsn: 'https://b456e2503df04a56aa95d236251106cb@sentry.io/1776236',
//   integrations: [new Integrations.Vue({Vue, attachProps: false})],
// })

const eventsHub = new Vue() //eslint-disable-line
import VueEvents from 'vue-events'
Vue.use(VueEvents)
Vue.use(Vuesax)
Vue.use(VueTour)
Vue.use(vueDebounce)

Vue.use(require('vue-moment-tz'), {
    moment
})
Vue.use(VueSweetalert2)
    // Vue.use(IdleVue,
    //   {
    //     store,
    //     eventEmitter: eventsHub,
    //     idleTime: process.env.VUE_APP_URL_API_TIME_LOGOUT
    //   }
    // )

ApiService.init()

axios.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
)

const successHandler = (response) => {
    return response
}

const errorHandler = (error) => {
    var status = {...error }
    if (!status.response) {
        return console.log(status, "Por favor revise su conexion a internet.")
    }
    if (status.response.status === 401) {
        store.commit('auth/SET_TOKE_FAILED', true)
    }
    return Promise.reject({...error })
}

// Globally Registered Components
import "./globalComponents.js"
// MapComponents
import "./componentsMap.js"
//SocketComponents
import "./componentsSockect.js"

// CSS
import "./assets/scss/main.scss"
import "./assets/css/main.css"
import "prismjs/themes/prism-tomorrow.css"
import './registerServiceWorker'

require('./assets/css/iconfont.css')
require('vue-tour/dist/vue-tour.css')

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
    // onIdle() {
    //   if(this.$router.currentRoute.name !== "Login"){
    //     this.$swal({
    //       position: 'center',
    //       type: 'warning',
    //       title: this.$t('inactivity_message'),
    //       confirmButtonColor: '#FDD402',
    //       showConfirmButton: true
    //       })
    //     this.$store.dispatch('auth/logout').then(()=>{
    //       this.$router.go()
    //     })
    //   }
    // }
}).$mount('#app')