import Config from "@/api/config/config" //eslint-disable-line

const actions = {
    set_sms({ commit }, val) {
        commit('setSms', val)
    },
    set_delete_sms({ commit }, val) {
        commit('setDeleteSms', val)
    }
}

export default actions