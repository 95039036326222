import JwtService from "@/jwt-service"

const mutations = {
  SET_CONFIG (state, response) {
    state.config = response
    JwtService.saveWebConfig(JSON.stringify(response))
  },
  UPDATE_CONFIG_PROPERTY (state, {property, value}) {
    const updatedState = {...state.config, [property]: value}
    state.config = updatedState
    JwtService.saveWebConfig(JSON.stringify(updatedState))
  }
}

export default mutations
