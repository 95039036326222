const getters = {
  getCompany (state) {
    return state.company
  },
  getDemo(state) {
    if (state.company) {
      return state.company.is_demo
    }
    return false
  }
}

export default getters