const actions = {
  set_newness({ commit }, val) {
    commit('setNewness', val)
  },
  set_delete_newness({ commit }, val) {
    commit('setDeleteNewness', val)
  }
}

export default actions
