import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

// Modules
import auth from './auth'
import language from './language'
import services from './services'
import statistics from './statistics'
import map from './map'
import roles from './roles'
import cedi from './cedi'
import vehicleServices from './vehicle'
import newnessServices from './newness'
import cedigroup from './cedigroup'
import userService from './users'
import Typology from './typology'
import OnCost from './oncost'
import Zonegroup from './zonegroup'
import Liquidation from './liquidation'
import config from './config'
import company from './company'
import status from './status'
import sms from './sms'
import whatsapp from './whatsapp'
import news from './news'

Vue.use(Vuex)

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth,
    language,
    map,
    roles,
    services,
    statistics,
    cedi,
    vehicleServices,
    newnessServices,
    cedigroup,
    userService,
    Typology,
    OnCost,
    Zonegroup,
    Liquidation,
    config,
    company,
    status,
    sms,
    whatsapp,
    news,
  },
  strict: false
})
