import JwtService from "@/jwt-service"

const mutations = {
  SET_ERROR (state, error) {
    state.errorsLogin = error
  },
  SET_AUTH (state, response) {
    state.isAuthenticated = true
    state.errorsLogin = null
    state.user = response.user
    state.token = response.token
    state.rol = response.roles
    state.permission = response.permission
    JwtService.saveToken(response.token)
    JwtService.saveUser(response.user)
    JwtService.saveRol(response.roles)
    JwtService.savePermission(response.permission)
    // JwtService.saveTableSettings(response.user)
  },
  SET_PROFILE (state, response) {
    state.user = response
    JwtService.saveUser(response)
    JwtService.saveTableSettings(response)
  },
  SET_USER_PROFILE (state, response) {
    state.user = response
  },
  SET_USER_ROLES (state, response) {
    state.rol = response
  },
  SET_USER_PERMISSION (state, response) {
    state.permission = response
  },
  SET_USER_TOKEN (state, response) {
    state.token = response
  },
  SET_USERS_PROFILE (state, val) {
    state.user = Object.assign({}, state.user, val)
  },
  SET_TOKE_FAILED (state, val) {
    state.tokenFailed = val
  },
  SET_FORDIBBEN_FAILED (state, data) {
    state.fordibbenAcces = data.val
    state.messagesError = data.messagesError
  },
  PURGE_AUTH (state) {
    state.isAuthenticated = false
    state.user = {}
    state.token = null
    state.errorsLogin = null
    JwtService.destroyToken()
    JwtService.destroyUser()
    JwtService.destroyRol()
    JwtService.destroyPermission()
    // JwtService.destroyTableSetting()
  },
}

export default mutations
