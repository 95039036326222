import JwtService from "@/jwt-service"
const state = {
  errorsLogin: null,
  user: {},
  rol: [],
  permission: [],
  token: null,
  tokenFailed: false,
  fordibbenAcces: 0,
  messagesError: null,
  isAuthenticated: (JwtService.getToken() != undefined) ? true : false 
}

export default state
