const actions = {
  set_cedigroup({ commit }, val) {
    commit('setCediGroup', val)
  },
  set_delete_cedigroup({ commit }, val) {
    commit('setDeleteCediGroup', val)
  }
}

export default actions
