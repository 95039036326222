import JwtService from "@/jwt-service"

const mutations = {
  SET_COMPANY (state, response) {
    state.company = response
    JwtService.saveCompanyConfig(JSON.stringify(response))
  },
  setCompany(state, data) {
    state.companyEdit = data
  },
  setDeleteCompany(state, data) {
    state.companyDelete = data
  }
}

export default mutations