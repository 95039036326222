const actions = {
  set_oncost({ commit }, val) {
    commit('setOnCost', val)
  },
  set_delete_oncost({ commit }, val) {
    commit('setDeleteOnCost', val)
  }
}

export default actions
