const actions = {
  example({ commit }, val) {
    commit('example', val)
  },
  setStatusFilter(context, statusFilter){
    context.commit('changeStatusFilter', statusFilter)
  }
}

export default actions

