import { AbilityBuilder } from '@casl/ability'

export default AbilityBuilder.define(can => {
    let userInfo = JSON.parse(localStorage.getItem('userInfo')) //eslint-disable-line
    let permission = JSON.parse(localStorage.getItem('permission'))
    if (permission) {
        getRules(can, permission)
    }
})

export function defineRules(userInfo) { //eslint-disable-line
    const { can, rules } = AbilityBuilder.extract()
    let permission = JSON.parse(localStorage.getItem('permission'))
    getRules(can, permission)
    return rules
}

export function validateUserInfoChange(prevUserInfo) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))

    if (userInfo !== prevUserInfo) {
        if (userInfo && prevUserInfo) {
            if (userInfo.id !== prevUserInfo.id) {
                return userInfo
            }
        } else if (userInfo) {
            return userInfo
        }
    }
    return false
}

function getRules(can, userInfo) {
    if (userInfo) {
        const subjectsWithActionsGroup = getSubjectsWithActions(userInfo)
        for (const subject in subjectsWithActionsGroup) {
            can(subjectsWithActionsGroup[subject], subject)
        }
    }
    const subjectsWithActions = getSubjectsWithActions(userInfo)
    for (const subject in subjectsWithActions) {
        can(subjectsWithActions[subject], subject)
    }
}

function getSubjectsWithActions(permissions) {
    let subjectsWithActions = {}

    for (const prop in permissions) {
        let codenameSplit = permissions[prop].split('_')
        let subject = codenameSplit[1]
        let action = codenameSplit[0]
        if (subjectsWithActions[subject]) {
            subjectsWithActions[subject].push(action)
        } else {
            subjectsWithActions[subject] = [action]
        }
    }
    return subjectsWithActions
}

export function validatePermission(reference, actionName, subjectName, routerName, action) {

    let hasPermission = false
    if (Array.isArray(subjectName)) {
        subjectName.forEach(subject => {
            if (reference.$can(actionName, subject)) {
                hasPermission = true
            }
        })
    } else if (reference.$can(actionName, subjectName)) {
        hasPermission = true
    }

    if (!hasPermission) {
        reference.$swal({
            position: 'center',
            type: 'warning',
            text: reference.$t('permission_denied')
        })
        reference.$router.push({ name: routerName })
    } else if (action) {
        action()
    }
}