const actions = {
  set_typology({ commit }, val) {
    commit('setTypology', val)
  },
  set_delete_typology({ commit }, val) {
    commit('setDeleteTypology', val)
  }
}

export default actions
