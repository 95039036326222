import HTTP from "@/http-common.js"
import JwtService from "@/jwt-service"

const actions = {
    login(context, credentials) {
        return new Promise((resolve, reject) => {
            HTTP.post("v1/users/login/", credentials.data).then((response) => {
                let resp = {
                    token: response.data.data.token,
                    roles: response.data.roles,
                    permission: response.data.permission,
                    user: response.data.user
                }
                context.commit('SET_AUTH', resp)
                resolve(response)
            }).catch((response) => {
                credentials.this.$vs.notify({
                    text: response.response.data.message,
                    color: 'danger',
                    iconPack: 'feather',
                    icon: 'icon-x-circle'
                })
                reject(response)
            })
        })
    },
    update_user(context, payload) {
        delete payload.payload.avatar
        HTTP.setHeader(true)
        return new Promise((resolve, reject) => {
            HTTP.put("/v1/users/update/" + payload.payload.username, payload.payload).then((response) => {
                context.commit('SET_AUTH', { user: response.data, token: JwtService.getToken() })
                if (payload.payload.updateUser) {
                    context.commit('SET_PROFILE', {...response.data, company_id: payload.payload.company_id })
                }
                resolve(response.data)
            }).catch(({ response }) => {
                context.commit('SET_ERROR', response.data)
                    // ErrorNotify.setError({
                    //   data: response.data,
                    //   ob: payload.this
                    // })
                reject(response)
            })
        })
    },
    logout(context) {
        return new Promise((resolve, reject) => { //eslint-disable-line
            HTTP.setHeader(false)
            context.commit('PURGE_AUTH')
        })
    }
}

export default actions