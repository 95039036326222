import Vue from "vue"
import Router from "vue-router"
import store from "@/store/store"

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [{
            path: '',
            component: () =>
                import ('./layouts/main/Main.vue'),
            children: [{
                    path: '/',
                    name: 'IndexHome',
                    component: () => import ( /* webpackChunkName: "home" */ './views/home/IndexHome.vue'),
                    beforeEnter: requiresAuth,
                    meta: {
                        pageTitle: 'home'
                    }
                },
                {
                    path: '/pages/user',
                    name: 'pageCreateUser',
                    component: () => import ( /* webpackChunkName: "user" */ '@/views/users/Index.vue'),
                    beforeEnter: requiresAuth,
                    meta: {
                        breadcrumb: [
                            { title: 'home', url: '/' },
                            { title: 'users.users_title', active: true },
                        ],
                        pageTitle: 'home'
                    }
                },
                {
                    path: '/news',
                    name: 'ManageNews',
                    component: () => import ( /* webpackChunkName: "user" */ '@/views/news/Index.vue'),
                    beforeEnter: requiresAuth,
                    meta: {
                        breadcrumb: [
                            { title: 'home', url: '/' },
                            { title: 'manage_news.manage_news', active: true },
                        ],
                        pageTitle: 'home'
                    }
                },
                {
                    path: '/pages/create-user',
                    name: 'pageUsersCreate',
                    component: () => import ( /* webpackChunkName: "createuser" */ '@/components/users/Form.vue'),
                    beforeEnter: requiresAuth,
                    meta: {
                        breadcrumb: [
                            { title: 'home', url: '/' },
                            { title: 'users.users_title', active: true },
                        ],
                        pageTitle: 'home'
                    }
                },
                {
                    path: '/pages/edit-user/:id',
                    name: 'pageUsersEdit',
                    component: () => import ( /* webpackChunkName: "edituser" */ '@/components/users/Form.vue'),
                    beforeEnter: requiresAuth,
                    meta: {
                        breadcrumb: [
                            { title: 'home', url: '/' },
                            { title: 'users.users_title', active: true },
                        ],
                        pageTitle: 'home'
                    }
                },
                {
                    path: '/companies',
                    name: 'Companies',
                    component: () => import ( /* webpackChunkName: "Companies" */ '@/views/companies/Index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'home', url: '/' },
                            { title: 'column.company', active: true },
                        ],
                        pageTitle: 'home'
                    }
                },
                {
                    path: '/pages/roles',
                    name: 'Role',
                    component: () => import ( /* webpackChunkName: "roles" */ '@/views/role/FormRoles.vue'),
                    beforeEnter: requiresAuth,
                    meta: {
                        breadcrumb: [
                            { title: 'home', url: '/roles' },
                            { title: 'role', active: true },
                        ],
                        pageTitle: 'home'
                    }
                },
                {
                    path: '/pages/edit-roles/:id',
                    name: 'pageRoleEdit',
                    component: () => import ( /* webpackChunkName: "edituser" */ '@/views/role/FormRoles.vue'),
                    beforeEnter: requiresAuth,
                    meta: {
                        breadcrumb: [
                            { title: 'home', url: '/' },
                            { title: 'role', active: true },
                        ],
                        pageTitle: 'home'
                    }
                },
                {
                    path: '/sms',
                    name: 'Sms',
                    component: () => import ( /* webpackChunkName: "Sms" */ '@/views/sms/Index.vue'),
                    beforeEnter: requiresAuth,
                    meta: {
                        breadcrumb: [
                            { title: 'home', url: '/roles' },
                            { title: 'role', active: true },
                        ],
                        pageTitle: 'home'
                    }
                },
                {
                    path: '/roles',
                    name: 'roles',
                    component: () => import ( /* webpackChunkName: "pagecedi" */ '@/views/role/Roles.vue'),
                    meta: {
                        breadcrumb: [{
                            title: 'Home',
                            url: '/'
                        }, {
                            title: 'roles.roles',
                            active: true
                        }, ],
                        pageTitle: 'roles.roles'
                    }
                },
                {
                    path: '/status',
                    name: 'Status',
                    component: () => import ( /* webpackChunkName: "Companies" */ '@/views/status/Index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'home', url: '/' },
                            { title: 'column.status', active: true },
                        ],
                        pageTitle: 'home'
                    }
                },
                {
                    path: '/services',
                    name: 'index-services',
                    component: () => import ( /* webpackChunkName: "ServicesCreate" */ '@/views/services/Index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'home', url: '/' },
                            { title: 'services', active: true },
                        ],
                        pageTitle: 'services'
                    }
                },
                {
                    path: '/custom-emails',
                    name: 'index-email',
                    component: () => import ( /* webpackChunkName: "CustomEmailCreate" */ '@/views/email/Index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'home', url: '/' },
                            { title: 'custom_email.title_index', active: true },
                        ],
                        pageTitle: 'custom_email.title_index'
                    }
                },
                {
                  path: '/activate-whatsapp',
                  name: 'index-whatsapp',
                  component: () => import ( /* webpackChunkName: "Whatsapp" */ '@/views/whatsapp/Index.vue'),
                  meta: {
                      breadcrumb: [
                          { title: 'home', url: '/' },
                          { title: 'whatsapp.title_index', active: true },
                      ],
                      pageTitle: 'whatsapp.title'
                  }
                },
                {
                  path: '/activate-help',
                  name: 'index-whatsapp-help',
                  component: () => import ( /* webpackChunkName: "WhatsappCompanies" */ '@/views/HelpCompanies/Index.vue'),
                  meta: {
                      breadcrumb: [
                          { title: 'home', url: '/' },
                          { title: 'help_companies.title', active: true },
                      ],
                      pageTitle: 'help_companies.title'
                  }
                },
                {
                    path: '/custom-emails/edit/:id',
                    name: 'edit-email',
                    component: () => import ( /* webpackChunkName: "CustomEmailEdit" */ '@/views/email/Edit.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'custom_email.title_index', url: '/custom-emails' },
                            { title: 'custom_email.title_edit', active: true },
                        ],
                        pageTitle: 'custom_email.title_edit'
                    }
                },
                {
                    path: '/custom-emails/new',
                    name: 'new-email',
                    component: () => import ( /* webpackChunkName: "CustomEmail" */ '@/views/email/New.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'index-email', url: '/custom-emails' },
                            { title: 'custom_email.title_new', active: true },
                        ],
                        pageTitle: 'custom_email.title_new'
                    }
                },
                {
                    path: '/webhooks/',
                    name: 'index-webhook',
                    component: () => import ( /* webpackChunkName: "Webhooks" */ '@/views/webhook/Index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'home', url: '/' },
                            { title: 'webhook.title', active: true },
                        ],
                        pageTitle: 'webhook.title'
                    }
                },
                {
                  path: '/create/client',
                  name: 'CreateClient',
                  component: () => import ( /* webpackChunkName: "CreateClient" */ '@/views/FormSp/Index.vue'),
                  meta: {
                    breadcrumb: [
                        { title: 'home', url: '/' },
                        { title: 'users.users_title', active: true },
                    ],
                    pageTitle: 'users.users_title'
                  }
                },
                {
                  path: '/demo_signup/prod',
                  name: 'demo_signup_pro',
                  component: () => import ( /* webpackChunkName: "demo_signup_pro" */ '@/views/demo/DemoSignUp.vue'),
                  meta: {
                    breadcrumb: [
                        { title: 'home', url: '/' },
                        { title: 'company_externo', active: true },
                    ],
                    pageTitle: 'company_externo'
                  }
                }
            ],
        },
        {
            path: '',
            component: () => import ('@/layouts/full-page/FullPage.vue'),
            children: [{
                    path: '/login',
                    name: 'Login',
                    component: () =>
                        import ( /* webpackChunkName: "login" */ '@/views/auth/Login.vue'),
                    beforeEnter: requiresAnonymous
                },
                {
                    path: '/recovery-password',
                    name: 'RecoveryPassword',
                    component: () =>
                        import ( /* webpackChunkName: "recoverypassword" */ '@/views/auth/RecoveryPassword.vue'),
                    beforeEnter: requiresAnonymous
                },
                {
                    path: '/recovery-password/reset/:uid/:code',
                    name: 'RecoveryPasswordReset',
                    component: () =>
                        import ( /* webpackChunkName: "passwordreset" */ '@/views/auth/ResetPasswordRecovery.vue'),
                    beforeEnter: requiresAnonymous
                },
                {
                    path: '/pages/error-404',
                    name: 'pageError404',
                    component: () =>
                        import ( /* webpackChunkName: "error404" */ '@/views/pages/Error404.vue')
                },
                {
                  path: '/exportable/tigo',
                  name: 'TIGOEXPORTABLE',
                  component: () => import ( /* webpackChunkName: "recoverypassword" */ '@/views/Tigo/Index.vue'),
                },
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/pages/error-404'
        }
    ],
})

// router.afterEach(() => {
//   // Remove initial loading
//   const appLoading = document.getElementById('loading-bg')
//     if (appLoading) {
//         appLoading.style.display = "none";
//     }
// })


function requiresAnonymous(to, from, next) {
    if (!store.getters['auth/isAuthenticated']) {
        return next()
    } else {
        return next({ name: 'Home' })
    }
}

function requiresAuth(to, from, next) {
    if (store.getters['auth/isAuthenticated']) {
        return next()
    } else {
        return next({ name: 'Login' })
    }
}

export default router