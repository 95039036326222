import Config from "@/api/config/config"

const actions = {
    set_company({ commit }, val) {
      commit('setCompany', val)
    },
    set_delete_company({ commit }, val) {
      commit('setDeleteCompany', val)
    },
    setCompany({commit}, id) {
        return new Promise( async (resolve) => {
            if (!id) {
                resolve(false)
            }
            const company = await Config.getCompany(id)
            if (company) {
                commit('SET_COMPANY', company.data)
                resolve(company)
            }
            else {
                resolve(false)
            }
        })
    },

    saveCompany({commit}, company) {
        return new Promise((resolve) => {
            if (!company) {
                resolve(false)
            }
            Config.saveCompany(company)
            .then(({data}) => {
                resolve(data)
                commit('SET_COMPANY', data)
            })
            .catch(() => {
                resolve(false)
            })
        })
    },
    update({commit}, company) {
        commit('SET_COMPANY', company)
    }
}

export default actions