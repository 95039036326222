import JwtService from "@/jwt-service"

const getters = {
  userGet () {
    return JSON.parse(JwtService.getUser())
  },
  currentToken (state) {
    return state.token
  },
  currentUser (state) {
    return state.user
  },
  currentRol (state) {
    return state.rol
  },
  isAuthenticated(state) {
    return state.isAuthenticated
  }
}

export default getters