import Vue from "vue"
import VueSocketIO from "vue-socket.io"
import axios from "axios" //eslint-disable-line
axios.post('https://ws.smartquick.com.co/autenticar', {
  usuario: 'enterprise',
  contrasena: '3nt3rpr1s3123*'
})
.then(function (response) {
  // console.log(response)
  localStorage.setItem('TokenSocket', response.data.token)
})
const options = { autoConnect: false }
Vue.use(new VueSocketIO({
    debug: process.env.NODE_ENV !== 'production',
    connection: process.env.VUE_APP_URL_SOCKET,
    options: options
}))

