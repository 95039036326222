const actions = {
  setCediReload ({ commit }, val) {
    commit('SET_CEDI_RELOAD', val)
  },
  set_cedi({ commit }, val) {
    commit('setCedi', val)
  },
  set_delete_cedi({ commit }, val) {
    commit('setDeleteCedi', val)
  }
}

export default actions

