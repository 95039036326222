const actions = {
  set_vehicle({ commit }, val) {
    commit('setVehicle', val)
  },
  set_delete_vehicle({ commit }, val) {
    commit('setDeleteVehicle', val)
  }
}

export default actions
