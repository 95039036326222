<template lang="pug">
    ul(class="list")
        li(
          class="list__item" v-for="(item, index) in list" :key="index"
        )
            feather-icon(
              :icon="icon"
              class="w-5 h-5 mr-1"
            )
              span(v-html="item")
</template>

<script>
export default {
  name: "vx-list",
  props: {
    list: {
      type: Array,
      required: true
    },
    icon: {
      type: String,
      default: "ChevronsRightIcon"
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/components/vxList.scss";
</style>