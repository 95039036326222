const actions = {
  set_user({ commit }, val) {
    commit('setUser', val)
  },
  set_user_map({ commit }, val) {
    commit('setUserMap', val)
  }
}

export default actions
