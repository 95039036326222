import Config from "@/api/config/config" //eslint-disable-line

const actions = {
    set_whatsapp({ commit }, val) {
        commit('setWhatsapp', val)
    },
    set_delete_whatsapp({ commit }, val) {
        commit('setDeleteWhatsapp', val)
    }
}

export default actions