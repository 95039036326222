const state = {
  example:null,
  colum_names_services_impor:[],
  disabled_geo: false,
  disabled_point: false,
  disabled_date: true,
  disabled_loading: true,
  statusFilter: null,
  service: null
}

export default state