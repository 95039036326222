import Config from "@/api/config/config"

const actions = {
    setConfig({commit}) {
        return new Promise( async (resolve) => {
            const config = await Config.getConfigWeb()
            if (config) {
                commit('SET_CONFIG', config.data[0])
                resolve(config)
            }
            else {
                resolve(false)
            }
        })
    },
    updateProperty({commit}, payload) {
        commit('UPDATE_CONFIG_PROPERTY', payload)
    },
    update({commit}, config) {
        commit('SET_CONFIG', config)
    }
}

export default actions