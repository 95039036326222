const actions = {
  set_zonegroup({ commit }, val) {
    commit('setZoneGroup', val)
  },
  set_delete_zonegroup({ commit }, val) {
    commit('setDeleteZoneGroup', val)
  }
}

export default actions
