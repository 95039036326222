const actions = {
  setFilterDate(context, filterDate) {
    context.commit('change', filterDate)
  },
  setCurrentTab(context, currentTab) {
    context.commit('changeTab', currentTab)
  }
}

export default actions

