import Config from "@/api/config/config" //eslint-disable-line

const actions = {
    set_news({ commit }, val) {
        commit('setNews', val)
    },
    set_delete_news({ commit }, val) {
        commit('setDeleteNews', val)
    }
}

export default actions