const mutations = {

  example(state, data) {
    state.example = data
  },
  change(state, data) {
    state.colum_names_services_impor = data
  },
  disabled_geo (state, val) {
    state.disabled_geo = val
  },
  disabled_point (state, val) {
    state.disabled_point = val
  },
  disabled_date (state, val) {
    state.disabled_date = val
  },
  disabled_loading (state, val) {
    state.disabled_loading = val
  },
  changeStatusFilter(state, status){
    state.statusFilter = status
  },
  SET_SERVICE(state, service){
    state.service = service
  }
}

export default mutations