import Config from "@/api/config/config" //eslint-disable-line

const actions = {
    set_status({ commit }, val) {
        commit('setStatus', val)
    },
    set_delete_status({ commit }, val) {
        commit('setDeleteStatus', val)
    }
}

export default actions