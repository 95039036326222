import HTTP from '@/http-common.js'


export default {
  getCongfig() {
    return new Promise((solve, reject) => {
      HTTP.get('/company/config').then(solve, reject).then(res => { return res }, err => { return err })
    })
  },
  getConfigWeb() {
    return new Promise((solve, reject) => {
      HTTP.get('/companies/config/web/').then(solve, reject).then(res => { return res }, err => { return err })
    })
  },
  getCompany(id) {
    return new Promise((solve, reject) => {
      HTTP.get(`/companies/${id}/`).then(solve, reject).then(res => { return res }, err => { return err })
    })
  },
  saveConfigWeb(data) {
    HTTP.setHeader(true)
    let request = [
      `/companies/config/web/${data.id}/update/`,
      data
    ]
    return new Promise((s, r) => {
      HTTP.put(...request).then(s, r).then(() => { return }, () => { return })
    })
  },
  saveCompany(data) {
    HTTP.setHeader(true)
    let request = [
      `/companies/${data.id}/`,
      data
    ]
    return new Promise((s, r) => {
      HTTP.put(...request).then(s, r).then(() => { return }, () => { return })
    })
  },
  saveConfigWebTableSettings(data) {
    HTTP.setHeader(true)
    let request = [
      `/companies/config/web/table_settings/`,
      data
    ]
    return new Promise((s, r) => {
      HTTP.put(...request).then(s, r).then(() => { return }, () => { return })
    })
  },
  saveCongfig(data) {
    HTTP.setHeader(true)
    let request = [
      '/company/config',
      data
    ]
    return new Promise((s, r) => {
      HTTP.post(...request).then(s, r).then(() => { return }, () => { return })
    })
  },
  saveConfigServiceOrder(data) {
    HTTP.setHeader(true)
    let request = [
      '/companies/config/web/service_order',
      data
    ]
    return new Promise((s, r) => {
      HTTP.put(...request).then(s, r).then(() => { return }, () => { return })
    })
  }
}