<template lang="pug">
.vx-breadcrumb
  ul.flex.flex-wrap.items-center
    li.inline-flex.items-end
      router-link(
        to='/'
      )
        feather-icon(
          icon='HomeIcon'
          svgClasses='h-5 w-5 mb-1 stroke-current text-primary'
        )
      span.breadcrumb-separator.mx-2
        feather-icon(
          icon='ChevronsRightIcon'
          svgClasses='w-4 h-4'
        )
    li.inline-flex.items-center(
      v-for='(link, index) in $route.meta.breadcrumb.slice(0,-1)'
      :key='index'
    )
      router-link(
        :to='link.url'
        v-if='link.url'
      ) {{ $t(link.title) }}
      span.text-primary.cursor-default(
        v-else=''
      ) {{ $t(link.title) }}
      span.breadcrumb-separator.mx-2.flex.items-start
        feather-icon(
          icon='ChevronsRightIcon'
          svgClasses='w-4 h-4'
        )
    li.inline-flex
      span.cursor-default(
        v-if='$route.meta.breadcrumb.slice(-1)[0].active'
      ) {{ $t($route.meta.breadcrumb.slice(-1)[0].title) }}
</template>

<script>
export default {
  name: "vx-breadcrumb"
};
</script>