const actions = {
  setCurrentRole(context,currentRole) {
    context.commit('change', currentRole)
  },
  set_roles({ commit }, val) {
    commit('setRoles', val)
  },
  set_delete_roles({ commit }, val) {
    commit('setDeleteRoles', val)
  }
}

export default actions

