const mutations = {
  change(state, currentRole) {
    state.currentRole = currentRole
  },
  setRoles(state, data) {
    state.rolesEdit = data
  },
  setDeleteRoles(state, data) {
    state.rolesDelete = data
  }
}

export default mutations