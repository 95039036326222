const mutations = {
  SET_CEDI_RELOAD(state, cediReload) {
    state.cediReload = cediReload
  },
  setCedi(state, data) {
    state.cediEdit = data
  },
  setDeleteCedi(state, data) {
    state.cediDelete = data
  }
}

export default mutations